.content {
  max-width: initial; }

.bl-n {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: none; }

.textile-storage-update-price {
  cursor: pointer; }
